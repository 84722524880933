import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Icon, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdAssignmentTurnedIn } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Предложения | Тату-салон Canvas Chronicles
			</title>
			<meta name={"description"} content={"Ваше воображение, наши чернила"} />
			<meta property={"og:title"} content={"Предложения | Тату-салон Canvas Chronicles"} />
			<meta property={"og:description"} content={"Ваше воображение, наши чернила"} />
			<meta property={"og:image"} content={"https://radiantomuse.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://radiantomuse.com/img/2635904.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://radiantomuse.com/img/2635904.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://radiantomuse.com/img/2635904.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://radiantomuse.com/img/2635904.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://radiantomuse.com/img/2635904.png"} />
			<meta name={"msapplication-TileImage"} content={"https://radiantomuse.com/img/2635904.png"} />
			<meta name={"msapplication-TileColor"} content={"https://radiantomuse.com/img/2635904.png"} />
		</Helmet>
		<Components.Header />
		<Section
			text-align="center"
			background-color="--green"
			color="--light"
			padding="80px 0"
			sm-padding="40px 0"
			background="rgba(0, 135, 90, 0.99)"
		>
			<Text
				as="h5"
				font="--lead"
				margin="10px 0 0 0"
				text-transform="uppercase"
				letter-spacing="5px"
			>
				Ознакомьтесь с нашими услугами
			</Text>
			<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0 0 0">
				Canvas Chronicles
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
			В тату-салоне Canvas Chronicles мы верим, что каждая татуировка - это глубоко личное выражение. Наши услуги разработаны с учетом ваших уникальных вкусов и предпочтений, чтобы каждое созданное нами произведение искусства было таким же индивидуальным, как и вы сами. Окунитесь в мир возможностей и узнайте, как мы можем воплотить ваши мечты о татуировке в жизнь.
			</Text>
			<Box min-width="100px" min-height="100px">
				<Button
					type="link"
					font="--headline3"
					text-decoration-line="initial"
					color="--light"
					border-radius="8px"
					border-width="1px"
					border-style="solid"
					border-color="--color-light"
					background="rgba(0, 119, 204, 0)"
					href="/contact-us"
				>
					Контакты
				</Button>
			</Box>
		</Section>
		<Section color="--dark">
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
			Наша экспертиза раскрыта
			</Text>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--green"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
				Индивидуальные дизайны татуировок
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Индивидуальные работы: Индивидуальный дизайн, отражающий вашу индивидуальность и идеи.
				<br/><br/>
Консультативный процесс: Работайте непосредственно с нашими художниками, чтобы уточнить ваше видение.
				<br/><br/>
Разнообразные стили: От традиционного до современного - мы владеем всеми этими стилями.

				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--green"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
				Переделка татуировок и маскировка
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Художественное преображение: Подарите старым татуировкам новую жизнь или скройте прошлые ошибки.
				<br/><br/>
Творческие решения: Экспертные советы по выбору оптимального подхода к переработке чернил.
				<br/><br/>
Бесшовные результаты: Безупречная интеграция с существующими татуировками.

				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--green"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
				Цветные и черно-серые татуировки
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Яркие цвета: Яркие, стойкие краски, которые выдержат испытание временем.
				<br/><br/>
Оттенки и тона: Экспертные техники затенения для придания глубины и реалистичности.
				<br/><br/>
Ориентированность на детали: Идеальное исполнение как смелых, так и замысловатых рисунков.

				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--green"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
				Портретные и реалистичные татуировки
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Реалистичный артистизм: Точно передайте суть любого объекта.
				<br/><br/>
Эмоциональные творения: Татуировки, которые рассказывают историю и вызывают чувства.
				<br/><br/>
Высокая четкость: Внимание к деталям, обеспечивающее максимальную реалистичность.

				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--green"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
				Безопасная и стерильная среда
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Санитария высшего класса: Протоколы здравоохранения и безопасности для вашей защиты.
				<br/><br/>
Гарантия качества: Краски премиум-класса и стерильное оборудование для каждого сеанса.
				<br/><br/>
Комфорт превыше всего: Непринужденная обстановка, в которой приоритетом является ваше благополучие.

				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-7">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					overflow-x="hidden"
					transform="translateY(0px)"
					padding="0px 0px 100% 0px"
					width="100%"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					height="auto"
				>
					<Image
						src="https://radiantomuse.com/img/2.jpg"
						object-fit="cover"
						position="absolute"
						width="100%"
						bottom={0}
						display="block"
						top={0}
						left={0}
						right={0}
						min-height="100%"
					/>
				</Box>
			</Box>
			<Box
				flex-wrap="wrap"
				flex-direction="row"
				lg-align-items="center"
				padding="4px 4px 4px 4px"
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
			>
				<Box width="50%" padding="12px 12px 12px 13px" display="flex">
					<Box
						width="100%"
						height="auto"
						position="relative"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						overflow-x="hidden"
						overflow-y="hidden"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
					>
						<Image
							top="auto"
							left={0}
							right={0}
							bottom="0px"
							display="block"
							object-fit="cover"
							position="absolute"
							width="100%"
							min-height="100%"
							src="https://radiantomuse.com/img/3.jpg"
						/>
					</Box>
				</Box>
				<Box padding="12px 12px 12px 12px" display="flex" width="50%">
					<Box
						transition="transform 0.2s ease-in-out 0s"
						hover-transform="translateY(-10px)"
						position="relative"
						transform="translateY(0px)"
						overflow-x="hidden"
						overflow-y="hidden"
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						width="100%"
						height="auto"
					>
						<Image
							bottom="0px"
							src="https://radiantomuse.com/img/4.jpg"
							position="absolute"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							display="block"
							width="100%"
							right={0}
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						transition="transform 0.2s ease-in-out 0s"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						width="100%"
					>
						<Image
							object-fit="cover"
							display="block"
							top="auto"
							right={0}
							bottom="0px"
							min-height="100%"
							src="https://radiantomuse.com/img/5.jpg"
							position="absolute"
							width="100%"
							left={0}
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						position="relative"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
						transform="translateY(0px)"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
					>
						<Image
							src="https://radiantomuse.com/img/6.jpg"
							position="absolute"
							display="block"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							width="100%"
							right={0}
							bottom="0px"
						/>
					</Box>
				</Box>
			</Box>
			<Box
				width="100%"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="0px 0px 20px 0px"
				display="flex"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					width="60%"
					lg-width="100%"
					md-text-align="left"
				>
					Загляните в тату-салон Canvas Chronicles и позвольте нам сделать татуировку, которая будет такой же необычной, как и вы. Свяжитесь с нами, чтобы записаться на консультацию или узнать больше о наших комплексных тату-услугах. Ваш путь к шедевру начинается здесь.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});